@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  body {
    @apply bg-black text-white;
  }
  /* Add default cursor for non-clickable text */
  p, h1, h2, h3, h4, h5, h6, span, div {
    cursor: default;
  }

  /* Preserve pointer cursor for clickable elements */
  a, button, [role="button"], input, select, textarea {
    cursor: pointer;
  }
}

@layer components {
  .btn-primary {
    @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500;
  }
  .feature-card {
    @apply transition-all duration-300 ease-in-out transform hover:-translate-y-2 hover:shadow-lg hover:shadow-primary-500/20;
  }
} 